@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
.theme-red-dark {
  --type: 'dark';
  --primary-color: #B6B6B6;
  --primary-color-400: #B6B6B6;
  --primary-color-600: #B6B6B6;
  --primary-color-80: #B6B6B6cc;
  --primary-color-50: #B6B6B680;
  --primary-color-30: #B6B6B64d;
  --secondary-color: #212121;
  --secondary-color-70: #212121b3;
  --secondary-color-50: #21212180;
  --tertiary-color: #eaeaea;
  --tertiary-color-80: #eaeaeacc;
  --tertiary-color-70: #eaeaeab3;
  --tertiary-color-50: #eaeaea80;
  --text-highlight-color: #fff;
  --primary-fonts: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-family: var(--primary-fonts);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to right, var(--secondary-color), var(--secondary-color));
  color: var(--primary-color);
}

p {
  color: var(--primary-color);
}